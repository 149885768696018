import React from 'react';
import Items from './Items';
import AddItem from './AddItem';
import Delete from './Delete';
import axios from "axios"

const HOME = 'https://todoapi.shamin.ru/'

class App extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            items : []
        }
        
        this.checkItem = this.checkItem.bind(this)
        this.addItem = this.addItem.bind(this)
        this.DeleteItem = this.DeleteItem.bind(this)
        this.Load = this.Load.bind(this)

    }
    render() {
        return (<div>
            <h3>Список оперативных дел</h3>
            <AddItem onAdd={this.addItem}/>
            <hr/>
            <Items onLoad={this.Load} items={this.state.items} onCheck={this.checkItem}/>
            <hr/>
            <Delete onDelete={this.DeleteItem}/>
       </div>)
    }

    async Load() {
        let allItems = []
        const host = axios.create({ baseURL: HOME })
        await host.get('api/load').then((resp) => {
            allItems = resp.data;
          });
        
        console.log(allItems.data)
        this.setState({items: allItems.data})
    }

    async DeleteItem() {
        const host = axios.create({ baseURL: HOME })
        let allItems = []
        this.state.items.forEach((el) => {
            if (el.done)
            {
                host.get('api/delete/' + el.id)
            }
            else
            {
                allItems.push(el)
            }
        })
        this.setState({items: allItems})
    }

    async Add(id, body, done) {
        const host = axios.create({ baseURL: HOME })
        await host.post('api/add', { id: id, body: body, done: done })
   }

    addItem(item) {
        let allItems = this.state.items
        let id = -1
        allItems.forEach((el)=>{ if(el.id > id) {id = el.id}})
        id += 1

        this.Add(id, item.body, false)

        allItems.push({id: id, body: item.body, done: false})
        this.setState({items: allItems})
    }

    checkItem(id) {
        const host = axios.create({ baseURL: HOME })
        let allItems = this.state.items
        let val = false
        allItems.forEach((el) => {if(el.id === id) {
            el.done = !el.done
            val = el.done
        }})

        host.get('api/check/' + id + '/' + val)

        this.setState({
            items : allItems
        })
    }
}

export default App
