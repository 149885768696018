import React from 'react';

class AddItem extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            body: ""
        }
    }

    render() {
        return (<form ref={(el)=>this.addForm = el}>
            <label>Новая задача: </label>
            <input placeholder='сделать' size={50} onChange={(e)=> this.setState({body: e.target.value})} />
            <button type='button' onClick={()=>{
                        if(this.state.body.length >= 1)
                        {
                            this.props.onAdd({body: this.state.body})
                            this.addForm.reset()
                            this.setState({body: ""})
                        }
                    }
                }>Добавить</button>
        </form>)
    }
}

export default AddItem