import React from 'react';

class Delete extends React.Component {
    render() {
        return (
            <button type='button' onClick={()=>{
                        this.props.onDelete()
                    }
                }>Удалить выполненные задачи</button>
        )
    }
}

export default Delete