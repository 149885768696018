import React from 'react';

class Item extends React.Component {
    item = this.props.item
    render() {
        if (this.item.done) {
            return (
                <div className='item' key={this.item.id}><input type='checkbox' checked onClick={()=>this.props.onCheck(this.item.id)} /><del>{this.item.body}</del></div>
            )
        }
        else {
        return (
<div className='item' key={this.item.id}><input type='checkbox' onClick={()=>this.props.onCheck(this.item.id)} />{this.item.body}</div>
               )
        }
    }
}

export default Item
