import React from 'react';
import Item from './Item'

class Items extends React.Component {
    constructor(props) {
        super(props)
        props.onLoad()
    }
    render() {
        if (this.props.items.length < 1)
        {
            return (<div>Пока список пуст.</div>)
        }
        else
        {
            return (<div>
                {
                    this.props.items.map((el) => (
                        <Item key={el.id} item={el} onCheck={this.props.onCheck}/>
                    ))
                }
            </div>)
        }
    }
}

export default Items